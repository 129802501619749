/*----------------------*\
  #GLOBALE
\*----------------------*/

.is-scrolling * {
  pointer-events: none !important;
}

@media (max-width: 768px) {
  [animate] {
    will-change: transform, opacity;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
}

/*----------------------*\
  #SECTION • HEADER
\*----------------------*/

@keyframes fadeInBg {
  from {
    background-image: url(https://cdn.prod.website-files.com/669d97109d5970e08c6816e1/66e4c5a1b219fd4f310d3338_65a8b56a2dd20fc19146a597_Background%20pattern.svg);
    opacity: 0;
  }
  to {
    background-image: url(https://cdn.prod.website-files.com/669d97109d5970e08c6816e1/66e4c5a1b219fd4f310d3338_65a8b56a2dd20fc19146a597_Background%20pattern.svg);
    opacity: 1;
  }
}

/*----------------------*\
  #SECTION • SERVICES
\*----------------------*/

@keyframes blob-services {
  0%,
  100% {
    left: 15%;
    top: 0%;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  33% {
    left: 80%;
    top: 40%;
    transform: translate(-50%, -50%) rotate(-360deg);
  }
  50% {
    left: 5%;
    top: 65%;
    transform: translate(-50%, -50%) rotate(-540deg);
  }
  75% {
    left: 90%;
    top: 100%;
    transform: translate(-50%, -50%) rotate(-1080deg);
  }
}

.blob-services {
  animation: blob-services 50s ease-in-out infinite;
}

@media screen and (max-width: 767px) {
  @keyframes blob-services-mobile {
    0%,
    100% {
      left: 20%;
      top: 5%;
      transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
      left: 80%;
      top: 70%;
      transform: translate(-50%, -50%) rotate(-540deg);
    }
  }

  .blob-services {
    animation: blob-services-mobile 20s ease-in-out infinite;
  }
}

.services_item_wrapper .service_image {
  transition: transform 300ms ease-in-out;
  transform: translateY(0) scale(1);
}

.services_item_wrapper:hover .service_image {
  transform: translateY(3px) scale(1.03);
}

@media screen and (max-width: 479px) {
  .services_item_wrapper:hover .service_image {
    transform: none;
  }
}

/*----------------------*\
  SECTION • PROJECT
\*----------------------*/

/* =========== SLIDER =========== */

.swiper-wrapper,
.swiper-slide {
  will-change: transform;
}

.testimonial_slider .swiper-slide {
  width: min(80%, 768px);
}

.testimonial_slider_logo {
  --mask-size: 70%;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    #ffffff calc(100% - var(--mask-size)),
    #ffffff calc(100% - (100% - var(--mask-size))),
    transparent
  );
  mask: linear-gradient(
    90deg,
    transparent,
    #ffffff calc(100% - var(--mask-size)),
    #ffffff calc(100% - (100% - var(--mask-size))),
    transparent
  );
}

.testimonial_slider_logo .swiper-slide {
  width: auto;
  height: auto;
}

.testimonial_slider_logo
  .swiper-slide:not(.swiper-slide-active)
  .testimonial_logo-image {
  filter: grayscale(100%);
  transform: scale(0.8);
}

.testimonial_progressbar::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--brand--orange);
  transform-origin: left center;
  transform: scaleX(0);
}

.swiper-slide-active .testimonial_progressbar::after {
  animation: progress calc(var(--animation-duration) * 1ms) linear forwards;
}

@keyframes progress {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

/* =========== SLIDER • ARROW  =========== */

.project_arrow:hover .project_arrow_content {
  background-color: var(--brand--white);
  transform: scale(1.15);
}

/*----------------------*\
  #SECTION • PROCESS
\*----------------------*/

/* =========== BACKGROUND  =========== */
@keyframes blob-process {
  0%,
  100% {
    left: 75%;
    top: 10%;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  33% {
    left: 20%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-360deg);
  }
  66% {
    left: 75%;
    top: 90%;
    transform: translate(-50%, -50%) rotate(-180deg);
  }
}

.blob-process {
  animation: blob-process 30s ease-in-out infinite;
}

@media screen and (max-width: 767px) {
  @keyframes blob-process-mobile {
    0%,
    100% {
      left: 20%;
      top: 5%;
      transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
      left: 80%;
      top: 70%;
      transform: translate(-50%, -50%) rotate(-540deg);
    }
  }

  .blob-process {
    animation: blob-process-mobile 20s ease-in-out infinite;
  }
}

/* =========== CARDS  =========== */

.process_item_image-blur {
  -webkit-mask: linear-gradient(#000 0%, #0000 20%);
  mask: linear-gradient(#000 0%, #0000 20%);
}

.process_item_container .process_item_image {
  transition: transform 300ms ease-in-out;
  transform: translateY(0) scale(1);
}

.process_item_container:hover .process_item_image {
  transform: translateY(3px) scale(1.03);
}

@media screen and (max-width: 479px) {
  .process_item_container:hover .process_item_image {
    transform: none;
  }
}

/*----------------------*\
  #SECTION • FAQ
\*----------------------*/

.faq_card_wrap:has(.faq_card_checkbox:checked) .faq_card_content {
  grid-template-rows: 1fr;
}
.faq_card_wrap:has(.faq_card_checkbox:checked) .faq_card_icon {
  transform: rotate(45deg);
}

/*----------------------*\
  #SECTION • RDV
\*----------------------*/

/* =========== BACKGROUND  =========== */
@keyframes blob-footer {
  0%,
  100% {
    left: 20%;
    top: 5%;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  33% {
    left: 70%;
    top: 40%;
    transform: translate(-50%, -50%) rotate(120deg);
  }
  66% {
    left: 30%;
    top: 60%;
    transform: translate(-50%, -50%) rotate(240deg);
  }
}

.blob-footer {
  animation: blob-footer 30s ease-in-out infinite;
}

@media screen and (max-width: 767px) {
  @keyframes blob-footer-mobile {
    0%,
    100% {
      left: 20%;
      top: 5%;
      transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
      left: 80%;
      top: 70%;
      transform: translate(-50%, -50%) rotate(-540deg);
    }
  }

  .blob-footer {
    animation: blob-footer-mobile 20s ease-in-out infinite;
  }
}

/*----------------------*\
  #FOOTER
\*----------------------*/

/* =========== WEATHER =========== */
.follower_follower {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.follower_outer-wrap:has(.follower_list:hover) .follower_follower {
  opacity: 1;
}
.follower_outer-wrap:has(.follower_list:hover) .follower_backed-by-text {
  opacity: 0;
}
